<template>
  <div class="searchresultbox_two d-flex align-items-center">
    <div class="box_one d-flex align-items-center">
      <div class="box_one_logo">
        <div class="airlogo">
          <img :src="airlineLogo" v-if="!unconfirmedUnauthorized" :alt="airlineName"/>
        </div>
        <h5>{{ airlineName }}</h5>
      </div>
      <div class="box_one_airlocation">
        <h4>
          <span>
            <img src="/assets/sabre-img/airplane1.png" alt="airplane1" v-if="isBack">
            <img src="/assets/sabre-img/airplane.png" alt="airplane" v-else>
          </span>
          {{$t("sabre.search-result.box.flight-to", {dest: arrivalCityName})}}
        </h4>
      </div>
    </div>
    <div class="box_two d-flex align-items-center justify-content-between">
      <div class="box_two_text">
        <h4 class="m-0 p-0"><strong>{{ departureTime }} | {{ departureDayName }}, {{ departureDate }}</strong></h4>
        <h4 class="m-0 p-0"><strong>{{ departureCityCode }}</strong> {{ departureCityName }}</h4>
      </div>
      <div class="box_two_midel">
        <p v-if="!unconfirmedUnauthorized">{{$t("sabre.search-result.box.flight-hour-minute", elapsedTime)}}</p>
        <div class="line"><span v-if="hasStops"></span></div>
        <p v-if="hasStops">{{$t("sabre.search-result.box.stopped")}}</p>
        <p v-else>{{$t("sabre.search-result.box.direct")}}</p>
      </div>
      <div class="box_two_text">
        <h4 class="m-0 p-0"><strong>{{ arrivalTime }} | {{ arrivalDayName }}, {{ arrivalDate }}</strong></h4>
        <h4 class="m-0 p-0"><strong>{{ arrivalCityCode }}</strong> {{ arrivalCityName }}</h4>
      </div>
    </div>

    <div class="box_three searchresultbox_left  d-flex align-items-center justify-content-end" v-if="kind==='changeFlight'">
      <div class="d-block">
        <div class="d-flex align-items-center justify-content-end">
          <div class="imgifo">
            <img src="/assets/sabre-img/trolley_allow.png" alt="trolley_allow" v-if="allowCabinBaggage">
            <img src="/assets/sabre-img/trolley_disallow.png" alt="trolley_disallow" v-else>
          </div>
          <div class="imgifo">
            <img src="/assets/sabre-img/Suitcase_allow.png" alt="Suitcase_allow" v-if="allowCheckinBaggage">
            <img src="/assets/sabre-img/Suitcase_disallow.png" alt="Suitcase_disallow" v-else>
          </div>

          <tooltip-item :allowCheckinBaggage="allowCheckinBaggage"
            :allowCabinBaggage="allowCabinBaggage"
            :checkinBaggageDescription="checkinBaggageDescription"
            :cabinBaggageDescription="cabinBaggageDescription"/>

        </div>
        <div class="d-flex align-items-center justify-content-end px-4">
          <a class="Closedetail_btn collapsed" data-bs-toggle="collapse" href="#directflight" role="button">
            {{$t("sabre.buttons.more-detail")}}
          </a>
        </div>
      </div>
    </div>

    <div class="box_three d-flex align-items-center justify-content-end" v-else>
      <div class="imgifo">
        <img src="/assets/sabre-img/trolley_allow.png" alt="trolley_allow" v-if="allowCabinBaggage">
        <img src="/assets/sabre-img/trolley_disallow.png" alt="trolley_disallow" v-else>
      </div>
      <div class="imgifo">
        <img src="/assets/sabre-img/Suitcase_allow.png" alt="Suitcase_allow" v-if="allowCheckinBaggage">
        <img src="/assets/sabre-img/Suitcase_disallow.png" alt="Suitcase_disallow" v-else>
      </div>

      <tooltip-item :allowCheckinBaggage="allowCheckinBaggage"
        :allowCabinBaggage="allowCabinBaggage"
        :checkinBaggageDescription="checkinBaggageDescription"
        :cabinBaggageDescription="cabinBaggageDescription"/>

    </div>
    <p v-if="unconfirmedMessage" style="position: absolute; bottom: -15px;">{{ unconfirmedMessage }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import searchResultBoxMixin from '../searchResultBoxMixin';

export default {
  mixins: [searchResultBoxMixin],
  components: {
    TooltipItem: () => import('@/sabre/common/atom/tooltipItem'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
  },
};
</script>
