import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const oneFlightBox = {
  props: {
    data: {
      type: Object,
      default: null,
    },
    isBack: {
      type: Boolean,
      default: false,
    },
    kind: {
      type: String,
      default: '',
    },
    checkinBaggage: {
      type: Object,
      default: null,
    },
    fcFlightAdditionalInfo: {
      type: Object,
      default: null,
    },
    fcFlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      destinationList: 'GET_DESTINATION_IMAGES',
      airlineCompanies: 'GET_SABRE_AIRLINE_COMPANIES',
      airports: 'GET_SABRE_AIRPORTS',
    }),

    // common part
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    airlineData() {
      const { data, airlineCompanies } = this;
      const code = data.excerpt?.airline || data.airline;

      return airlineCompanies?.find((c) => c.barcode === code) || null;
    },
    airlineLogo() {
      const { airlineData, data, unconfirmedUnauthorized } = this;
      const code = data.excerpt?.airline || data.airline;
      if (unconfirmedUnauthorized) return '';
      // https://content.airhex.com/content/logos/airlines_${code}_180_100_s.png
      // https://daisycon.io/images/airline/?width=180&height=100&iata=${code}
      // https://pics.avs.io/180/100/${code}.png
      return airlineData?.desc2 || `https://pics.avs.io/180/100/${code}.png`;
    },
    airlineName() {
      const { airlineData, data } = this;
      return airlineData?.desc1 || data.excerpt?.airline || data.airline;
    },
    hasStops() {
      // this is for only flight item
      const { data } = this;
      return data.excerpt.connections > 0;
    },
    departureFlightNumber() {
      // this is for only collapse item
      const { data, unconfirmedUnauthorized } = this;
      if (unconfirmedUnauthorized) return '';
      return `${data.airline}${data.flightNumber}`;
    },
    allowCheckinBaggage() {
      const { data, checkinBaggage } = this;
      const baggageData = data.excerpt?.checkinBaggageAllowance || checkinBaggage;
      return (baggageData?.byPieces && baggageData?.pieceCount > 0) || (!baggageData?.byPieces && baggageData?.weight > 0);
    },
    checkinBaggageDescription() {
      const { data, checkinBaggage } = this;
      const baggageData = data.excerpt?.checkinBaggageAllowance || checkinBaggage;

      // eslint-disable-next-line no-nested-ternary
      return (baggageData?.description1 || baggageData?.description2)
        ? `${baggageData?.description1 || ''} \n ${baggageData?.description2 || ''}`
        : baggageData?.weight ? `${this.$t('sabre.search-result.box.include')} ${baggageData?.weight} ${baggageData?.unit}` : '';
    },
    allowCabinBaggage() {
      const { data } = this;
      const baggageData = data.excerpt?.cabinBaggageAllowance || data.cabinBaggageAllowance;
      return (baggageData?.byPieces && baggageData?.pieceCount > 0) || (!baggageData?.byPieces && baggageData?.weight > 0);
    },
    cabinBaggageDescription() {
      const { data } = this;
      const baggageData = data.excerpt?.cabinBaggageAllowance || data.cabinBaggageAllowance;

      // eslint-disable-next-line no-nested-ternary
      return (baggageData?.description1 || baggageData?.description2)
        ? `${baggageData?.description1 || ''} \n ${baggageData?.description2 || ''}`
        : baggageData?.weight ? `${this.$t('sabre.search-result.box.include')} ${baggageData?.weight} ${baggageData?.unit}` : '';
    },

    // departure part
    departureCityCode() {
      const { data } = this;
      return data.excerpt?.departure?.city || data.departure?.city || data.excerpt?.departure?.airport || data.departure?.airport || '';
    },
    departureCityName() {
      const { destinations, departureCityCode } = this;
      return destinations.find((d) => d.code === departureCityCode)?.name_Loc?.content || '';
    },
    departureCityEnName() {
      const { destinations, departureCityCode } = this;
      return destinations.find((d) => d.code === departureCityCode)?.name || '';
    },
    departureDayjsObj() {
      const { data } = this;
      const oTime = data.excerpt?.departure.time || data.departure.time;
      return dayjs(new Date(`${data.departureDate} ${oTime}`));
    },
    departureDate() {
      const { departureDayjsObj } = this;
      return dayjs(departureDayjsObj).format('DD/MM');
    },
    departureTime() {
      // const { departureDayjsObj, unconfirmedUnauthorized } = this;
      const { data, unconfirmedUnauthorized } = this;
      if (unconfirmedUnauthorized) return 'NOT_FINAL';
      // return dayjs(departureDayjsObj).format('HH:mm');
      const oTime = data.excerpt?.departure.time || data.departure.time;
      return oTime.substr(0, 5);
    },
    departureDayName() {
      const { departureDayjsObj } = this;
      const weekNameArray = Object.values(this.$t('weekName'));
      return weekNameArray[dayjs(departureDayjsObj).day()];
    },
    elapsedTime() {
      const { data } = this;
      const et = data.excerpt?.elapsedTime || data.elapsedTime;
      return { hour: Math.floor(et / 60), minute: et % 60 };
    },

    // arrival part
    arrivalCityCode() {
      const { data } = this;
      return data.excerpt?.arrival?.city || data.arrival?.city || data.excerpt?.arrival?.airport || data.arrival?.airport || '';
    },
    arrivalCityName() {
      const { destinations, arrivalCityCode } = this;
      return destinations.find((d) => d.code === arrivalCityCode)?.name_Loc?.content || '';
    },
    arrivalCityEnName() {
      const { destinations, arrivalCityCode } = this;
      return destinations.find((d) => d.code === arrivalCityCode)?.name || '';
    },
    arrivalOriginDate() {
      const { data } = this;
      const dateAdjustment = data.excerpt?.arrival?.dateAdjustment || data.arrival?.dateAdjustment || 0;
      return dayjs(data.departureDate).add(dateAdjustment, 'day').format('YYYY-MM-DD');
    },
    arrivalDayjsObj() {
      const { data, arrivalOriginDate } = this;
      const oTime = data.excerpt?.arrival.time || data.arrival.time;
      return dayjs(new Date(`${arrivalOriginDate} ${oTime}`));
    },
    arrivalDate() {
      const { arrivalDayjsObj } = this;
      return dayjs(arrivalDayjsObj).format('DD/MM');
    },
    arrivalTime() {
      // const { arrivalDayjsObj, unconfirmedUnauthorized } = this;
      const { data, unconfirmedUnauthorized } = this;
      if (unconfirmedUnauthorized) return 'NOT_FINAL';
      // return dayjs(arrivalDayjsObj).format('HH:mm');
      const oTime = data.excerpt?.arrival.time || data.arrival.time;
      return oTime.substr(0, 5);
    },
    arrivalDayName() {
      const { arrivalDayjsObj } = this;
      const weekNameArray = Object.values(this.$t('weekName'));
      return weekNameArray[dayjs(arrivalDayjsObj).day()];
    },
    /**
     * If this flight is fcFlight and unconfirmed or unauthorized then show a message and adequate handle
     */
    unconfirmedUnauthorized() {
      const { data, fcFlight, fcFlightAdditionalInfo } = this;
      /**
       * fcFlightAdditionalInfo: this variable is for collapse only
       */
      const rslt = fcFlightAdditionalInfo ? fcFlight
        && (fcFlightAdditionalInfo.airlineNotConfirmed
        || fcFlightAdditionalInfo.flightTimeNotConfirmed)
        : fcFlight
        && (data.excerpt?.fcFlightAdditionalInfo?.airlineNotConfirmed
        || data.excerpt?.fcFlightAdditionalInfo?.flightTimeNotConfirmed);
      return rslt;
    },
    unconfirmedMessage() {
      const { unconfirmedUnauthorized, data } = this;
      if (!unconfirmedUnauthorized) return '';
      return data.excerpt?.fcFlightAdditionalInfo?.authorizationMsg || '';
    },
  },
};

export default oneFlightBox;
